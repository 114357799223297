'use client';

import { useContext } from 'react';
import { CrossPlatform } from '@deltasierra/frontend/cross-platform';
import { CrossPlatformContext } from '../contexts';

export const useCrossPlatform = (): typeof CrossPlatform => {
    const crossPlatform = useContext(CrossPlatformContext);
    if (crossPlatform === null) {
        throw new Error('The crossPlatform does not exist yet');
    }
    return crossPlatform;
};
