import { graphql } from '../../__graphqlTypes';

export const HIDE_USER_ASSET_LIBRARY_PERMISSIONS_POPUP = graphql(`
    mutation UpdatePermissionNode($input: HideUserAssetLibraryPermissionsPopupInput!) {
        hideUserAssetLibraryPermissionsPopup(input: $input) {
            ... on HideUserAssetLibraryPermissionsPopupError {
                message
            }
            ... on HideUserAssetLibraryPermissionsPopupSuccess {
                user {
                    id
                    hidAssetLibraryPermissionsPopupAt
                }
            }
        }
    }
`);
