import { graphql } from '../../__graphqlTypes';

export const GET_CLIENT_FEATURE_FLAGS = graphql(`
    query GetClientFeatureFlags($clientId: ID!) {
        client(id: $clientId) {
            id
            features {
                ...ClientFeaturesFragment
            }
        }
    }
`);
