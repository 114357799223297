'use client';

import { captureException } from '@sentry/browser';
import { useRef } from 'react';
import { useAppConfig } from './useAppConfig';

type HotjarCommand = 'event' | 'identify' | 'stateChange';
type HotjarUserInfo = Record<number | string, Date | boolean | number | string>;

export class HotjarService {
    public get isReady(): boolean {
        if (window.hj) {
            return true;
        }
        return false;
    }

    public constructor(public readonly isEnabled: boolean) {}

    public event(actionName: string): boolean {
        if (!this.isEnabled) {
            return true;
        }

        try {
            this.executeHotjarCommand('event', actionName);
            return true;
        } catch (error) {
            this.handleError(error);
            return false;
        }
    }

    public identify(userId: string | null, userInfo: HotjarUserInfo): boolean {
        if (!this.isEnabled) {
            return true;
        }

        try {
            this.executeHotjarCommand('identify', userId, userInfo);
            return true;
        } catch (error) {
            this.handleError(error);
            return false;
        }
    }

    public stateChange(relativePath: string): boolean {
        if (!this.isEnabled) {
            return true;
        }

        try {
            this.executeHotjarCommand('stateChange', relativePath);
            return true;
        } catch (error) {
            this.handleError(error);
            return false;
        }
    }

    private handleError(error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);
        captureException(error);
    }

    private executeHotjarCommand(command: HotjarCommand, ...args: unknown[]) {
        if (!this.isReady || !window.hj) {
            throw Error('Hotjar is not available yet, make sure init has been called.');
        }

        return window.hj(command, ...args);
    }
}

export function useHotjar(): HotjarService {
    const appConfig = useAppConfig();

    const proxyRef = useRef<HotjarService | null>(null);
    if (proxyRef.current === null) {
        proxyRef.current = new HotjarService(!!appConfig.googleTagManagerId);
    }

    return proxyRef.current;
}
