import { graphql } from '../../__graphqlTypes';

export const GET_USER_ASSET_PERMISSION_MODAL_STATUS = graphql(`
    query GetMe {
        me {
            id
            hidAssetLibraryPermissionsPopupAt
        }
    }
`);
