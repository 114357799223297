import { Box, LinearProgress, Link, Typography, alpha } from '@deltasierra/react/components/core';
import { ImagePlus } from '@deltasierra/react/icons';
import { Translations } from '@deltasierra/translations/react';
import { ChangeEventHandler, DragEvent, DragEventHandler, MouseEventHandler, RefObject } from 'react';

const textStyleProps = { userSelect: 'none' } as const;

type AssetRef = RefObject<HTMLInputElement> | ((element: HTMLInputElement | null) => void);

export type AssetLibraryUploadProps = {
    assetRef: AssetRef;
    isLoadingUpload: boolean;
    onChangeFiles: ChangeEventHandler<HTMLInputElement>;
    onClick: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
    onDropFiles: DragEventHandler<HTMLDivElement>;
    t: Translations<'AssetLibrary'>;
};

export const AssetLibraryUpload = ({
    assetRef,
    isLoadingUpload,
    onChangeFiles,
    onClick,
    onDropFiles,
    t,
}: AssetLibraryUploadProps): JSX.Element => (
    <Box
        alignItems="center"
        color="primary.dark"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        sx={theme => ({
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
                color: 'primary.dark',
            },
            height: '100%',
            width: '100%',
        })}
        onClick={onClick}
        onDragOver={event => event.preventDefault()}
        onDrop={(event: DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();
            onDropFiles(event);
        }}
    >
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                path: { strokeWidth: 0.7 },
                rowGap: '1rem',
            }}
        >
            <ImagePlus height="5rem" width="5rem" />
            {isLoadingUpload ? (
                <LinearProgress
                    aria-label={t('Uploading asset')}
                    sx={{
                        height: '0.75rem',
                        marginTop: '0.75rem',
                        width: '30rem',
                    }}
                />
            ) : (
                <Box columnGap="0.25rem" display="flex" flexDirection="row">
                    <Link color="primary.dark" component="button" variant="h5" onClick={onClick}>
                        {t('Add asset')}
                    </Link>
                    <input
                        accept="image/*"
                        aria-label={t('Upload asset')}
                        hidden
                        multiple={false}
                        ref={assetRef}
                        type="file"
                        onChange={onChangeFiles}
                    />
                    <Typography color="common.black" component="span" sx={textStyleProps} variant="h5">
                        {t('or drag and drop')}
                    </Typography>
                </Box>
            )}
        </Box>
    </Box>
);

AssetLibraryUpload.displayName = 'AssetLibraryUpload';
