import { Box, Divider, Tab, Tabs, Typography } from '@deltasierra/react/components/core';
import { ReactNode } from 'react';

export type AssetLibraryTabsProps = {
    currentTabIndex: number;
    onChangeTabs: (_: unknown, newTab: number) => void;
    tabs: Array<{
        tabText: string;
        tabPanel: ReactNode;
    }>;
};

function generateIds(tabText: string, index: number): { tabId: string; tabPanelId: string } {
    const id = `${tabText.replaceAll(' ', '')}${index}`;
    return {
        tabId: `tab-${id}`,
        tabPanelId: `tabPanel-${id}`,
    };
}

const UPLOAD_INDEX = 0;

export const AssetLibraryTabs = ({ currentTabIndex, onChangeTabs, tabs }: AssetLibraryTabsProps): JSX.Element => (
    <Box
        sx={{
            flex: 1,
            overflow: 'hidden',
            position: 'sticky',
        }}
    >
        <Tabs value={currentTabIndex} onChange={onChangeTabs}>
            {tabs.map(({ tabText }, index) => {
                const { tabId, tabPanelId } = generateIds(tabText, index);
                return (
                    <Tab
                        aria-controls={tabPanelId}
                        id={tabId}
                        key={tabId}
                        label={
                            <Typography color={currentTabIndex !== index ? 'grey.500' : undefined} variant="h6">
                                {tabText}
                            </Typography>
                        }
                    />
                );
            })}
        </Tabs>
        <Divider />
        {tabs.map(({ tabPanel, tabText }, index) => {
            const { tabId, tabPanelId } = generateIds(tabText, index);
            return (
                <Box
                    aria-labelledby={tabId}
                    hidden={currentTabIndex !== index}
                    id={tabPanelId}
                    key={tabPanelId}
                    role="tabpanel"
                    sx={{
                        '&:not([hidden])': {
                            display: 'flex',
                            flexDirection: 'column',
                            overscrollBehavior: 'contain',
                            scrollbarGutter: 'stable both-edges',
                        },
                        height: '95%',
                        overflowY: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            padding: currentTabIndex !== UPLOAD_INDEX ? 0 : '34px 1.5rem',
                        }}
                    >
                        {currentTabIndex === index && <Box sx={{ flex: 1 }}>{tabPanel}</Box>}
                    </Box>
                </Box>
            );
        })}
    </Box>
);

AssetLibraryTabs.displayName = 'AssetLibraryTabs';
