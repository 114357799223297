'use client';

import { useEffect } from 'react';
import { setUser } from '@sentry/browser';
import { AppGlobalData } from '../types';

export function useRegisterSentryUser(data: AppGlobalData): void {
    useEffect(() => {
        const user = data.me;

        setUser({
            email: user.username,
            id: user.id,
            username: user.username,
        });
    }, [data]);
}
