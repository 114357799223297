import { graphql } from '../../__graphqlTypes';

export const GET_LOCATION_ASSET_COLLECTIONS = graphql(`
    query GetLocationAssetCollections($locationId: ID!) {
        location(id: $locationId) {
            id
            title
            collections {
                edges {
                    node {
                        id
                        ...CollectionFragment
                    }
                }
            }
        }
    }
`);
