'use client';

import { useContext } from 'react';
import { GlobalDataContext } from '../contexts';
import { AppGlobalData } from '../types';

export function useGlobalData(): AppGlobalData {
    const value = useContext(GlobalDataContext);
    if (value === null) {
        throw new Error('The global data context has not been initialised yet');
    }
    return value;
}
