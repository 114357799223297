import { Translations } from '@deltasierra/translations/react';
import { useRef } from 'react';
import { AssetLibraryUpload } from './AssetLibraryUpload';
import {
    AssetLibraryUploadPermissionModal,
    AssetLibraryUploadPermissionModalProps,
} from './AssetLibraryUploadPermissionModal';

export type AssetLibraryUploadContainerProps = Pick<
    AssetLibraryUploadPermissionModalProps,
    | 'isCheckedConfirmRead'
    | 'isCheckedNeverAgain'
    | 'onChangeConfirmRead'
    | 'onChangeNeverAgain'
    | 'onClickUploadCancel'
    | 'onClickUploadNext'
> & {
    isOpenPermissionModal: boolean;
    onChangeAssets: (F: File | undefined) => void;
    t: Translations<'AssetLibrary'>;
    isLoadingUpload: boolean;
};

export const AssetLibraryUploadContainer = ({
    isCheckedConfirmRead,
    isCheckedNeverAgain,
    isLoadingUpload,
    isOpenPermissionModal,
    onChangeAssets,
    onChangeConfirmRead,
    onChangeNeverAgain,
    onClickUploadCancel,
    onClickUploadNext,
    t,
}: AssetLibraryUploadContainerProps): JSX.Element => {
    const assetRef = useRef<HTMLInputElement | null>(null);

    return (
        <>
            <AssetLibraryUpload
                assetRef={assetRef}
                isLoadingUpload={isLoadingUpload}
                t={t}
                onChangeFiles={event => onChangeAssets(event.target.files?.[0])}
                onClick={event => {
                    event.stopPropagation();
                    assetRef.current?.click();
                }}
                onDropFiles={event => onChangeAssets(event.dataTransfer.files[0])}
            />
            {isOpenPermissionModal && (
                <AssetLibraryUploadPermissionModal
                    isCheckedConfirmRead={isCheckedConfirmRead}
                    isCheckedNeverAgain={isCheckedNeverAgain}
                    t={t}
                    onChangeConfirmRead={onChangeConfirmRead}
                    onChangeNeverAgain={onChangeNeverAgain}
                    onClickUploadCancel={onClickUploadCancel}
                    onClickUploadNext={onClickUploadNext}
                />
            )}
        </>
    );
};

AssetLibraryUploadContainer.displayName = 'AssetLibraryUploadContainer';
