'use client';

import { QueryResult, useLazyQuery } from '@apollo/client';
import { captureException } from '@sentry/browser';
import {
    ASSET_FILE_FRAGMENT,
    ASSET_FOLDER_FRAGMENT,
    COLLECTION_FRAGMENT,
    GET_ASSETS_ON_COLLECTION,
    GET_ASSETS_ON_FOLDER,
    GET_CLIENT_ASSET_COLLECTIONS,
    GET_LOCATION_ASSET_COLLECTIONS,
    GetAssetsOnCollectionQuery,
    GetAssetsOnCollectionQueryVariables,
    GetAssetsOnFolderQuery,
    GetAssetsOnFolderQueryVariables,
    GetClientAssetCollectionsQuery,
    GetClientAssetCollectionsQueryVariables,
    GetLocationAssetCollectionsQuery,
    GetLocationAssetCollectionsQueryVariables,
    getFragmentData,
    relayConnectionToArray,
    useConnectionFetchMore,
} from '@deltasierra/frontend/graphql';
import { AlertContextType } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { useCallback, useEffect, useMemo } from 'react';
import { CollectionItem, Folder, ModalCollection } from '../types';
import { ModalAsset } from '../contexts';

const ASSETS_TO_FETCH = 30;

type LazyAssets = QueryResult<GetAssetsOnCollectionQuery, GetAssetsOnCollectionQueryVariables>;
type LazyFolders = QueryResult<GetAssetsOnFolderQuery, GetAssetsOnFolderQueryVariables>;

export type UseAssetLibraryCollectionsAndFoldersReturns = {
    assets: ModalAsset[];
    collections: ModalCollection[];
    hasMoreElements: boolean;
    isLoadingAssets: boolean;
    isLoadingCollections: boolean;
    isLoadingFolders: boolean;
    refetchAssets: LazyAssets['refetch'];
    refetchFolder: LazyFolders['refetch'];
    handleFetchMoreAssets: () => void;
    handleFetchMoreAssetsOnFolder: () => void;
};

// eslint-disable-next-line jsdoc/require-jsdoc, max-params, max-lines-per-function
export function useAssetLibraryCollectionsAndFolders(
    clientId: string,
    currentCollection: CollectionItem | null,
    currentFolders: Folder[],
    searchInputValue: string,
    showAlert: AlertContextType['showAlert'],
    t: Translations<'AssetLibrary'>,
    locationId?: string,
): UseAssetLibraryCollectionsAndFoldersReturns {
    const [
        getAssets,
        { data: dataAssets, fetchMore: fetchMoreAssets, loading: isLoadingAssets, refetch: refetchAssets },
    ] = useLazyQuery<GetAssetsOnCollectionQuery, GetAssetsOnCollectionQueryVariables>(GET_ASSETS_ON_COLLECTION, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data.collection.__typename !== 'Collection') {
                showAlert('error', t('Assets cannot be loaded'), t('Please try again'), data.collection);
            }
        },
        onError: error => showAlert('error', t('Assets cannot be loaded'), t('Please try again'), error),
        variables: {
            filter: { search: searchInputValue },
            first: ASSETS_TO_FETCH,
            id: currentCollection?.id ?? '',
        },
    });
    const [
        getFolderAssets,
        {
            called: isCalledFolderAssets,
            data: dataFolderAssets,
            fetchMore: fetchMoreAssetsOnFolder,
            loading: isLoadingFolders,
            refetch: refetchFolder,
        },
    ] = useLazyQuery<GetAssetsOnFolderQuery, GetAssetsOnFolderQueryVariables>(GET_ASSETS_ON_FOLDER, {
        fetchPolicy: 'network-only',
        onCompleted: data =>
            data.asset.__typename !== 'AssetFolder' &&
            showAlert('error', t('Assets cannot be loaded'), t('Please try again'), data.asset),
        onError: error => showAlert('error', t('Assets cannot be loaded'), t('Please try again'), error),
        variables: {
            filter: { search: searchInputValue },
            first: ASSETS_TO_FETCH,
            id: currentFolders.at(-1)?.id ?? '',
        },
    });
    const [
        getLocationCollections,
        {
            called: isCalledLocationCollections,
            data: dataLocationCollection,
            loading: isLoadingLocationCollections,
            refetch: refreshLocationCollection,
        },
    ] = useLazyQuery<GetLocationAssetCollectionsQuery, GetLocationAssetCollectionsQueryVariables>(
        GET_LOCATION_ASSET_COLLECTIONS,
        {
            onError: error => showAlert('error', t('Collections cannot be loaded'), t('Please try again'), error),
            variables: { locationId: locationId ?? '' },
        },
    );
    const [
        getClientCollections,
        {
            called: isCalledClientCollections,
            data: dataClientCollection,
            loading: isLoadingClientCollections,
            refetch: refreshClientCollection,
        },
    ] = useLazyQuery<GetClientAssetCollectionsQuery, GetClientAssetCollectionsQueryVariables>(
        GET_CLIENT_ASSET_COLLECTIONS,
        {
            onError: error => showAlert('error', t('Collections cannot be loaded'), t('Please try again'), error),
            variables: { id: clientId },
        },
    );

    const getLocationAndMakeCollectionQuery = useCallback(async () => {
        try {
            if (isLoadingLocationCollections || isLoadingClientCollections) {
                return;
            }
            if (isCalledClientCollections) {
                await refreshClientCollection();
            } else {
                await getClientCollections();
            }
            if (locationId) {
                if (isCalledLocationCollections) {
                    await refreshLocationCollection();
                } else {
                    await getLocationCollections();
                }
            }
        } catch (error) {
            captureException(error);
            showAlert('error', t('Collections cannot be loaded'), t('Please try again'), error);
        }
    }, [
        isLoadingLocationCollections,
        isLoadingClientCollections,
        isCalledClientCollections,
        locationId,
        refreshClientCollection,
        getClientCollections,
        isCalledLocationCollections,
        refreshLocationCollection,
        getLocationCollections,
        showAlert,
        t,
    ]);
    const getCollectionAndMakeAssetQuery = useCallback(() => {
        const newFolderId = currentFolders.at(-1)?.id;

        if (newFolderId && !dataFolderAssets && !isCalledFolderAssets) {
            void getFolderAssets({ variables: { id: newFolderId } });
        } else {
            void getAssets();
        }
    }, [dataFolderAssets, isCalledFolderAssets, getAssets, getFolderAssets, currentFolders]);

    useEffect(() => {
        if (
            (!dataLocationCollection && !isCalledClientCollections) ||
            (!dataLocationCollection?.location && !isLoadingLocationCollections)
        ) {
            void getLocationAndMakeCollectionQuery();
        }
        if (currentCollection) {
            getCollectionAndMakeAssetQuery();
        }
    }, [
        currentCollection,
        dataLocationCollection,
        getCollectionAndMakeAssetQuery,
        getLocationAndMakeCollectionQuery,
        isCalledClientCollections,
        isLoadingLocationCollections,
    ]);
    useEffect(() => {
        const newFolderId = currentFolders.at(-1)?.id;
        if (newFolderId) {
            void getFolderAssets({ variables: { id: newFolderId } });
        }
    }, [currentFolders, getFolderAssets]);

    const [handleFetchMoreAssets, hasMoreAssets] = useConnectionFetchMore(
        dataAssets?.collection.__typename === 'Collection' ? dataAssets.collection.assets : null,
        async after =>
            fetchMoreAssets({
                variables: { after, first: ASSETS_TO_FETCH, id: currentCollection?.id ?? '' },
            }),
    );
    const [handleFetchMoreAssetsOnFolder, hasMoreAssetsOnFolder] = useConnectionFetchMore(
        dataFolderAssets?.asset.__typename === 'AssetFolder' ? dataFolderAssets.asset.assets : null,
        async after =>
            fetchMoreAssetsOnFolder({
                variables: { after, first: ASSETS_TO_FETCH, id: currentCollection?.id ?? '' },
            }),
    );

    const assets: ModalAsset[] = useMemo(() => {
        if (currentFolders.length > 0) {
            if (dataFolderAssets?.asset.__typename === 'AssetFolder') {
                return relayConnectionToArray(dataFolderAssets.asset.assets).map(asset => {
                    if (asset.__typename === 'AssetFile') {
                        return getFragmentData(ASSET_FILE_FRAGMENT, asset);
                    } else {
                        return getFragmentData(ASSET_FOLDER_FRAGMENT, asset);
                    }
                });
            }
        } else if (dataAssets?.collection.__typename === 'Collection') {
            return relayConnectionToArray(dataAssets.collection.assets).map(asset => {
                if (asset.__typename === 'AssetFile') {
                    return getFragmentData(ASSET_FILE_FRAGMENT, asset);
                } else {
                    return getFragmentData(ASSET_FOLDER_FRAGMENT, asset);
                }
            });
        }
        return [];
    }, [currentFolders.length, dataAssets, dataFolderAssets]);
    const collections: ModalCollection[] = useMemo(() => {
        const sectionList = [];

        if (dataClientCollection?.client) {
            const clientCollectionItems: CollectionItem[] = relayConnectionToArray(
                dataClientCollection.client.collections,
            ).map(collection => getFragmentData(COLLECTION_FRAGMENT, collection));

            sectionList.push({
                data: clientCollectionItems,
                id: dataClientCollection.client.id,
                title: dataClientCollection.client.title,
            });
        }

        if (locationId && dataLocationCollection?.location) {
            const locationCollectionItems: CollectionItem[] = relayConnectionToArray(
                dataLocationCollection.location.collections,
            ).map(collection => getFragmentData(COLLECTION_FRAGMENT, collection));
            sectionList.push({
                data: locationCollectionItems,
                id: dataLocationCollection.location.id,
                title: dataLocationCollection.location.title,
            });
        }
        return sectionList;
    }, [dataClientCollection, dataLocationCollection, locationId]);
    return {
        assets,
        collections,
        handleFetchMoreAssets,
        handleFetchMoreAssetsOnFolder,
        hasMoreElements: currentFolders.length > 0 ? hasMoreAssetsOnFolder : hasMoreAssets,
        isLoadingAssets,
        isLoadingCollections: isLoadingLocationCollections || isLoadingClientCollections,
        isLoadingFolders,
        refetchAssets,
        refetchFolder,
    };
}
