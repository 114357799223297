import { graphql } from '../../__graphqlTypes';

export const GET_ASSETS_ON_FOLDER = graphql(`
    query GetAssetsOnFolder($after: String, $id: ID!, $first: Int, $filter: SearchCollectionAssetsInput) {
        asset(id: $id) {
            ... on AssetFolder {
                id
                assets(after: $after, filter: $filter, first: $first) {
                    edges {
                        node {
                            ...AssetFileFragment
                            ...AssetFolderFragment
                        }
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        }
    }
`);
