import { graphql } from '../../__graphqlTypes';

export const GET_ASSETS_ON_COLLECTION = graphql(`
    query GetAssetsOnCollection($after: String, $id: ID!, $filter: SearchCollectionAssetsInput, $first: Int) {
        collection(id: $id) {
            ... on Collection {
                id
                title
                permissions
                assets(after: $after, filter: $filter, first: $first) {
                    edges {
                        node {
                            ...AssetFileFragment
                            ...AssetFolderFragment
                        }
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
            ... on CollectionNotFoundError {
                code
                message
            }
            ... on CollectionPermissionError {
                code
                message
            }
        }
    }
`);
