import { Box, Tooltip } from '@deltasierra/react/components/core';
import { Image03 } from '@deltasierra/react/icons';
import Image from 'next/image';
import {
    AssetLibraryThumbnailFolderContainer,
    AssetLibraryThumbnailFolderContainerProps,
} from './AssetLibraryThumbnailFolderContainer';
import { ModalAsset } from './contexts';

export type AssetLibraryThumbnailProps = Pick<
    AssetLibraryThumbnailFolderContainerProps,
    'dimensions' | 'folderActions' | 'onClickThumbnail' | 't'
> & {
    asset: ModalAsset;
    disabled: boolean;
};

export const AssetLibraryThumbnail = ({
    asset,
    dimensions,
    disabled,
    folderActions,
    onClickThumbnail,
    t,
}: AssetLibraryThumbnailProps): JSX.Element =>
    asset.__typename === 'AssetFile' && 'mimeType' in asset ? (
        <Tooltip title={asset.title}>
            <Box
                component="div"
                sx={[
                    {
                        '&>*': {
                            '&:hover': {
                                cursor: disabled ? 'not-allowed' : 'pointer',
                                outlineColor: 'primary.main',
                                outlineStyle: 'solid',
                                outlineWidth: '2px',
                            },
                            ...(disabled && {
                                backgroundColor: asset.thumbnails?.medium.signedUrl ? 'grey.A400' : 'primary.light',
                                opacity: 0.5,
                            }),
                        },
                    },
                ]}
                onClick={onClickThumbnail}
            >
                {asset.thumbnails?.medium.signedUrl ? (
                    <Image
                        alt={asset.title || 'image'}
                        height={dimensions.height}
                        src={asset.thumbnails.medium.signedUrl}
                        style={{
                            borderRadius: '8px',

                            // If the image is relatively wide , scale thumbnail down, else fill.
                            objectFit:
                                dimensions.width > dimensions.height * 1.5 && asset.thumbnails?.medium.signedUrl
                                    ? 'scale-down'
                                    : 'fill',
                        }}
                        width={dimensions.width}
                    />
                ) : (
                    <Box
                        color="primary.dark"
                        sx={{
                            alignItems: 'center',
                            backgroundColor: 'primary.light',
                            display: 'flex',
                            flexDirection: 'column',
                            height: dimensions.height,
                            justifyContent: 'center',
                            path: { strokeWidth: '1' },
                            width: dimensions.width,
                        }}
                    >
                        <Image03 height="5.25rem" width="5.25rem" />
                    </Box>
                )}
            </Box>
        </Tooltip>
    ) : (
        <AssetLibraryThumbnailFolderContainer
            asset={asset}
            dimensions={dimensions}
            folderActions={folderActions}
            t={t}
            onClickThumbnail={onClickThumbnail}
        />
    );

AssetLibraryThumbnail.displayName = 'AssetLibraryThumbnail';
