'use client';

import { useContext } from 'react';
import { NavigationState, NavigationStateContext } from '../utils/NavigationStateContext';

export const useNavigationStateContext = (): NavigationState => {
    const context = useContext(NavigationStateContext);
    if (!context) {
        throw new Error('NavigationStateContext must be used within a NavigationContextProvider');
    }
    return context;
};
