import {
    AssetLibraryModalContext,
    AssetLibraryModalContextType,
} from '@deltasierra/react/features/asset-library-modal';
import { useContext } from 'react';

export const useAssetLibraryModal = (): AssetLibraryModalContextType => {
    const context = useContext(AssetLibraryModalContext);
    if (!context) {
        throw new Error('useAssetLibraryModal must be used within the AssetLibraryModalProvider');
    }
    return context;
};
