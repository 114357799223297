'use client';

import { AlertContextType } from '@deltasierra/react/components/core';
import { WatchElementRefType, useInfiniteScroll } from '@deltasierra/react/hooks/common';
import { Translations } from '@deltasierra/translations/react';
import { captureException } from '@sentry/browser';
import { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { ModalAsset } from '../contexts';
import { CollectionId, CollectionItem, Folder, FolderAction, ModalCollection } from '../types';
import { ResultAssetFile } from '../types/ResultAssetFile';
import { useAssetLibraryCollectionsAndFolders } from './useAssetLibraryCollectionsAndFolders';

const CLIENT = 0;
const LOCATION = 1;

type UseAssetLibraryBrowseOptions = {
    clientId: string;
    locationId?: string | undefined;
    onAssetSelected: (asset: ResultAssetFile) => void;
    onCloseModal: () => void;
    showAlert: AlertContextType['showAlert'];
    t: Translations<'AssetLibrary'>;
};

// eslint-disable-next-line max-lines-per-function
export function useAssetLibraryBrowse({
    clientId,
    locationId,
    onAssetSelected,
    onCloseModal,
    showAlert,
    t,
}: UseAssetLibraryBrowseOptions): {
    assets: ModalAsset[];
    collections: ModalCollection[];
    currentCollection: CollectionItem | null;
    currentFolders: Folder[];
    folderActions: FolderAction[];
    isLoadingAssets: boolean;
    isLoadingCollections: boolean;
    isOpenCollection: Set<CollectionId>;
    onChangeSearch: ChangeEventHandler<HTMLInputElement>;
    onClickBreadcrumb: (id?: string) => void;
    onClickHeader: (collectionId: string) => void;
    onClickSearch: () => void;
    onClickSelectCollection: (collection: CollectionItem) => void;
    onClickThumbnail: (item: ModalAsset) => void;
    onRefetchCurrentAssets: () => void;
    search: string;
    watchElementRef: WatchElementRefType | false;
} {
    const [isOpenCollection, setIsOpenCollection] = useState<Set<CollectionId>>(new Set());
    const [currentCollection, setCurrentCollection] = useState<CollectionItem | null>(null);
    const [currentFolders, setCurrentFolders] = useState<Folder[]>([]);
    const [currentSearchTerm, setCurrentSearchTerm] = useState('');
    const [searchInputValue, setSearchInputValue] = useState('');

    const {
        assets,
        collections,
        handleFetchMoreAssets,
        handleFetchMoreAssetsOnFolder,
        hasMoreElements,
        isLoadingAssets,
        isLoadingCollections,
        isLoadingFolders,
        refetchAssets,
        refetchFolder,
    } = useAssetLibraryCollectionsAndFolders(
        clientId,
        currentCollection,
        currentFolders,
        searchInputValue,
        showAlert,
        t,
        locationId,
    );

    useEffect(() => {
        try {
            if (collections.length === 0 || isLoadingCollections) {
                return;
            }
            if (collections[CLIENT] && collections[CLIENT].data.length > 0) {
                setCurrentCollection(collections[CLIENT].data[0]);
            } else if (collections[LOCATION] && collections[LOCATION].data.length > 0) {
                setCurrentCollection(collections[LOCATION].data[0]);
            } else {
                setCurrentCollection(null);
            }
            const newOpenCollections = [collections[CLIENT].id];
            if (collections[LOCATION]) {
                newOpenCollections.push(collections[LOCATION].id);
            }
            setIsOpenCollection(new Set(newOpenCollections));
        } catch (error) {
            setCurrentCollection(null);
            setIsOpenCollection(new Set());
            onCloseModal();
            captureException(error);
            showAlert('error', t('Asset Library is not available'));
        }
    }, [collections, isLoadingCollections, onCloseModal, showAlert, t]);

    const onClickThumbnail = useCallback(
        (item: ModalAsset) => {
            if (item.__typename === 'AssetFolder') {
                return setCurrentFolders(prev => [...prev, { id: item.id, title: item.title }]);
            }
            const { id, url } = item;
            return onAssetSelected({ id, url });
        },
        [onAssetSelected],
    );
    const onClickHeader = useCallback(
        (id: string) =>
            setIsOpenCollection(prev => {
                const newCollection = new Set(prev);
                if (prev.has(id)) {
                    newCollection.delete(id);
                } else {
                    newCollection.add(id);
                }
                return newCollection;
            }),
        [],
    );
    const onClickSelectCollection = useCallback((collection: CollectionItem) => {
        setCurrentCollection(collection);
        setCurrentFolders([]);
    }, []);
    const onClickBreadcrumb = useCallback(
        (id?: string) => {
            if (typeof id === 'string') {
                const newFolderIndex = currentFolders.findIndex(folder => folder.id === id);
                if (newFolderIndex > -1) {
                    setCurrentFolders(prev => prev.slice(0, newFolderIndex + 1));
                    return;
                }
            }
            setCurrentFolders([]);
        },
        [currentFolders],
    );
    const handleSetSearchTerm = (searchInput: string) => {
        if (searchInput === '') {
            // If user is clearing the search input, immediately do a search query to return default
            setSearchInputValue(searchInput);
        }
        // In every case set the search term to what was entered
        setCurrentSearchTerm(searchInput);
    };
    const onElementVisible = useCallback(() => {
        if (currentFolders.length > 0) {
            if (!isLoadingFolders) {
                handleFetchMoreAssetsOnFolder();
            }
        } else if (!isLoadingAssets) {
            handleFetchMoreAssets();
        }
    }, [currentFolders, isLoadingAssets, isLoadingFolders, handleFetchMoreAssetsOnFolder, handleFetchMoreAssets]);
    const { watchElementRef } = useInfiniteScroll(onElementVisible);

    return {
        assets,
        collections,
        currentCollection,
        currentFolders,
        folderActions: [],
        isLoadingAssets: isLoadingFolders || isLoadingAssets,
        isLoadingCollections,
        isOpenCollection,
        onChangeSearch: (event: ChangeEvent<HTMLInputElement>) => handleSetSearchTerm(event.target.value),
        onClickBreadcrumb,
        onClickHeader,
        onClickSearch: () => setSearchInputValue(currentSearchTerm),
        onClickSelectCollection,
        onClickThumbnail,
        onRefetchCurrentAssets: currentFolders.length > 0 ? refetchFolder : refetchAssets,
        search: currentSearchTerm,
        watchElementRef: hasMoreElements && watchElementRef,
    };
}
