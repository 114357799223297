import {
    Box,
    Button,
    DropdownUncontrolledOpen,
    IconButton,
    InputAdornment,
    MenuItem,
    MenuList,
    TextField,
    TextFieldProps,
    Typography,
} from '@deltasierra/react/components/core';
import { SearchSm, XClose } from '@deltasierra/react/icons';
import { theme } from '@deltasierra/react/theme';
import { Translations } from '@deltasierra/translations/react';

const HEIGHT_STYLE = { height: '3.5rem' } as const;

export type AssetLibrarySearchAndSortProps = {
    onChangeSearch: TextFieldProps['onChange'];
    onClickClose: () => void;
    onClickSearch: () => void;
    onClickSortBy: (T: string) => void;
    onClickView: (T: string) => void;
    search: string;
    sortByOptions: string[];
    t: Translations<'AssetLibrary'>;
    viewOptions: string[];
};

// eslint-disable-next-line max-lines-per-function
export const AssetLibrarySearchAndSort = ({
    onChangeSearch,
    onClickClose,
    onClickSearch,
    onClickSortBy,
    onClickView,
    search,
    sortByOptions,
    t,
    viewOptions,
}: AssetLibrarySearchAndSortProps): JSX.Element => (
    <Box
        sx={{
            alignItems: 'center',
            columnGap: '3rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            minHeight: '7.5rem',
            paddingTop: '1.5rem',
            paddingX: '1.5rem',
            width: '100%',
        }}
    >
        <IconButton
            aria-label={t('Close')}
            sx={{
                color: 'primary.main',
                position: 'absolute',
                right: '0.2rem',
                top: '0.2rem',
            }}
            onClick={onClickClose}
        >
            <XClose />
        </IconButton>
        <Typography variant="h2">{t('Choose your asset')}</Typography>
        <Box
            sx={{
                columnGap: '0.5rem',
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'center',
            }}
        >
            <TextField
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchSm color={theme.palette.primary.main} />
                        </InputAdornment>
                    ),
                }}
                placeholder={t('Search assets and folders')}
                sx={[
                    {
                        flex: 1,
                        minWidth: '24rem',
                    },
                    HEIGHT_STYLE,
                ]}
                value={search}
                onChange={onChangeSearch}
            />
            <Button
                sx={[
                    HEIGHT_STYLE,
                    {
                        minWidth: '6rem',
                    },
                ]}
                variant="contained"
                onClick={onClickSearch}
            >
                <Typography component="h3" variant="h5">
                    {t('Search')}
                </Typography>
            </Button>
        </Box>
        <Box
            sx={[
                {
                    columnGap: '0.5rem',
                    display: 'flex',
                    flexDirection: 'row',
                },
                HEIGHT_STYLE,
            ]}
        >
            {sortByOptions.length > 0 && (
                <DropdownUncontrolledOpen
                    ariaLabel={t('Sort by')}
                    buttonChildren={
                        <Typography
                            component="h4"
                            sx={{
                                textAlign: 'start',
                                width: '12.5rem',
                            }}
                            variant="h5"
                        >
                            {t('Sort by')}
                        </Typography>
                    }
                    buttonSx={HEIGHT_STYLE}
                >
                    {onCloseDropdown => (
                        <MenuList sx={{ width: '16.5rem' }}>
                            {sortByOptions.map(option => (
                                <MenuItem
                                    key={option}
                                    sx={{ paddingX: 3 }}
                                    onClick={event => {
                                        onCloseDropdown();
                                        onClickSortBy(option);
                                        event.stopPropagation();
                                    }}
                                >
                                    <Typography>{option}</Typography>
                                </MenuItem>
                            ))}
                        </MenuList>
                    )}
                </DropdownUncontrolledOpen>
            )}
            {viewOptions.length > 0 && (
                <DropdownUncontrolledOpen
                    ariaLabel={t('View')}
                    buttonChildren={
                        <Typography
                            component="h4"
                            sx={{
                                textAlign: 'start',
                                width: '12.5rem',
                            }}
                            variant="h5"
                        >
                            {t('View')}
                        </Typography>
                    }
                    buttonSx={HEIGHT_STYLE}
                >
                    {onCloseDropdown => (
                        <MenuList sx={{ width: '16.5rem' }}>
                            {viewOptions.map(option => (
                                <MenuItem
                                    key={option}
                                    sx={{ paddingX: 3 }}
                                    onClick={event => {
                                        onCloseDropdown();
                                        onClickView(option);
                                        event.stopPropagation();
                                    }}
                                >
                                    <Typography>{option}</Typography>
                                </MenuItem>
                            ))}
                        </MenuList>
                    )}
                </DropdownUncontrolledOpen>
            )}
        </Box>
    </Box>
);

AssetLibrarySearchAndSort.displayName = 'AssetLibrarySearchAndSort';
