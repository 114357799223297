import { useContext } from 'react';
import { AlertContextType } from '@deltasierra/react/components/core';
import { AlertContext } from '../contexts/AlertContext';

export const useAlert = (): AlertContextType => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within a AlertProvider');
    }
    return context;
};
