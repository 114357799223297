/* eslint-disable jsdoc/require-jsdoc */
'use client';

import { useMutation, useQuery } from '@apollo/client';
import {
    GET_USER_ASSET_PERMISSION_MODAL_STATUS,
    GetMeQuery,
    GetMeQueryVariables,
    HIDE_USER_ASSET_LIBRARY_PERMISSIONS_POPUP,
} from '@deltasierra/frontend/graphql';
import { useCallback, useEffect, useState } from 'react';

export function useAssetLibraryPermissionModal(): {
    isOpenPermissionModal: boolean;
    isCheckedConfirmRead: boolean;
    isCheckedNeverAgain: boolean;
    onChangeIsCheckedConfirmRead: (T: boolean) => void;
    onChangeIsCheckedNeverAgain: (T: boolean) => void;
    onChangeIsOpenPermissionModal: (T: boolean) => void;
} {
    const [isCheckedConfirmRead, setIsCheckedConfirmRead] = useState(false);
    const [isCheckedNeverAgain, setIsCheckedNeverAgain] = useState(false);
    const [isOpenPermissionModal, setIsOpenPermissionModal] = useState(false);
    const [userId, setUserId] = useState<string | null>(null);

    const { data } = useQuery<GetMeQuery, GetMeQueryVariables>(GET_USER_ASSET_PERMISSION_MODAL_STATUS);
    useEffect(() => {
        if (data) {
            setIsCheckedNeverAgain(!!data.me.hidAssetLibraryPermissionsPopupAt);
            setUserId(data.me.id);
        }
    }, [data]);

    const [onHideUserAssetLibraryPermissionsPopup] = useMutation(HIDE_USER_ASSET_LIBRARY_PERMISSIONS_POPUP);
    const onChangeIsOpenPermissionModal = useCallback(
        (isOpen: boolean) => {
            setIsOpenPermissionModal(isOpen);

            if (!isOpen && isCheckedNeverAgain && userId) {
                void onHideUserAssetLibraryPermissionsPopup({
                    variables: { input: { id: userId } },
                });
            }
        },
        [isCheckedNeverAgain, onHideUserAssetLibraryPermissionsPopup, userId],
    );
    return {
        isCheckedConfirmRead,
        isCheckedNeverAgain,
        isOpenPermissionModal,
        onChangeIsCheckedConfirmRead: setIsCheckedConfirmRead,
        onChangeIsCheckedNeverAgain: setIsCheckedNeverAgain,
        onChangeIsOpenPermissionModal,
    };
}
