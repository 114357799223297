'use client';

import {
    AlertContextType,
    EMAIL_ALLOWED_MIME_TYPES,
    EMAIL_MAX_FILE_SIZE_BYTES,
    FormControlLabelProps,
} from '@deltasierra/react/components/core';
import { useCallback, useState } from 'react';
import { useAssetLibraryPermissionModal } from './useAssetLibraryPermissionModal';
import { AssetLibraryUploadDestination, useUploadAssetToLibrary } from './useUploadAssetToLibrary';
import { useValidate } from './useValidate';

const UPLOAD_POSITION_ID = 'useAssetLibraryUpload';

type UseAssetLibraryUploadOptions = {
    onCloseModal: () => void;
    showAlert: AlertContextType['showAlert'];
    uploadDestination: AssetLibraryUploadDestination;
};

export function useAssetLibraryUpload({ onCloseModal, showAlert, uploadDestination }: UseAssetLibraryUploadOptions): {
    isCheckedConfirmRead: boolean;
    isCheckedNeverAgain: boolean | undefined;
    isLoadingUpload: boolean;
    isOpenPermissionModal: boolean;
    onChangeAssets: (F: File | undefined) => void;
    onChangeConfirmRead: FormControlLabelProps['onChange'];
    onChangeNeverAgain: FormControlLabelProps['onChange'];
    onClickUploadCancel: () => void;
    onClickUploadNext: () => void;
} {
    const [pendingFile, setPendingFile] = useState<File | null>(null);

    const {
        isCheckedConfirmRead,
        isCheckedNeverAgain,
        isOpenPermissionModal,
        onChangeIsCheckedConfirmRead,
        onChangeIsCheckedNeverAgain,
        onChangeIsOpenPermissionModal,
    } = useAssetLibraryPermissionModal();

    const { uploadFileToAssetLibrary, uploadingAssets } = useUploadAssetToLibrary();

    const { validateFileIsAllowedTypeAndSize } = useValidate(showAlert);

    const validateFile = useCallback(
        (file: File) => {
            const { isValid } = validateFileIsAllowedTypeAndSize(
                file,
                EMAIL_ALLOWED_MIME_TYPES,
                EMAIL_MAX_FILE_SIZE_BYTES,
            );
            return isValid;
        },
        [validateFileIsAllowedTypeAndSize],
    );

    const onProgressUpload = useCallback(
        async (file: File) => {
            try {
                await uploadFileToAssetLibrary({
                    ...uploadDestination,
                    file,
                    id: UPLOAD_POSITION_ID,
                });
                onCloseModal();
            } finally {
                onChangeIsCheckedConfirmRead(false);
                setPendingFile(null);
            }
        },
        [uploadDestination, onChangeIsCheckedConfirmRead, onCloseModal, uploadFileToAssetLibrary],
    );
    const onChangeAssets = useCallback(
        (file: File | undefined) => {
            if (!file) {
                throw new Error('File not found');
            }
            const isValid = validateFile(file);
            if (!isValid) {
                return;
            }
            if (isCheckedNeverAgain) {
                void onProgressUpload(file);
            } else {
                setPendingFile(file);
                onChangeIsOpenPermissionModal(true);
            }
        },
        [isCheckedNeverAgain, onChangeIsOpenPermissionModal, onProgressUpload, validateFile],
    );

    const onClickUploadCancel = useCallback(() => {
        onChangeIsOpenPermissionModal(false);
        setPendingFile(null);
    }, [onChangeIsOpenPermissionModal]);

    const onClickUploadNext = useCallback(() => {
        if (isCheckedConfirmRead && pendingFile) {
            void onProgressUpload(pendingFile);
            onChangeIsOpenPermissionModal(false);
        }
    }, [isCheckedConfirmRead, onChangeIsOpenPermissionModal, onProgressUpload, pendingFile]);

    return {
        isCheckedConfirmRead,
        isCheckedNeverAgain,
        isLoadingUpload: uploadingAssets.includes(UPLOAD_POSITION_ID),
        isOpenPermissionModal,
        onChangeAssets,
        onChangeConfirmRead: (_, checked) => onChangeIsCheckedConfirmRead(checked),
        onChangeNeverAgain: (_, checked) => onChangeIsCheckedNeverAgain(checked),
        onClickUploadCancel,
        onClickUploadNext,
    };
}
