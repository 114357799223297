import { graphql } from '../../__graphqlTypes';

export const CLIENT_FEATURES_FRAGMENT = graphql(`
    fragment ClientFeaturesFragment on ClientFeatures {
        dashboardV2
        enableEmailTemplatePlaceholder
        enableEmailTemplateSpacer
        enableEmailTemplateDivider
        enableNewGalleryPage
        isAssetLibraryV2Enabled
        socialReportingTemplates
        showOldEmailTemplatesOption
        templateBuilder
        templateGallery
    }
`);
