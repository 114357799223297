import { graphql } from '../../__graphqlTypes';

export const GET_CLIENT_ASSET_COLLECTIONS = graphql(`
    query GetClientAssetCollections($id: ID!) {
        client(id: $id) {
            id
            title
            collections {
                edges {
                    node {
                        id
                        ...CollectionFragment
                    }
                }
            }
        }
    }
`);
