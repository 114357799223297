import { List, Skeleton } from '@deltasierra/react/components/core';

export type SimpleSkeletonListProps = {
    skeleton?: number[];
};

export const SimpleSkeletonList = ({ skeleton }: SimpleSkeletonListProps): JSX.Element => (
    <>
        {skeleton?.map((header, i) => (
            <>
                <Skeleton key={i} sx={{ marginX: '10%' }} />
                <List
                    disablePadding
                    sx={{
                        marginX: '10%',
                        paddingRight: '20%',
                    }}
                >
                    {Array(header)
                        .fill('')
                        .map((_, index) => (
                            <Skeleton component="li" key={index} />
                        ))}
                </List>
            </>
        ))}
    </>
);

SimpleSkeletonList.displayName = 'SimpleSkeletonList';
