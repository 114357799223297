import { AlertContextType } from '@deltasierra/react/components/core';
import { useTranslations } from '@deltasierra/translations/react';
import { EmailAllowedFileValidationType } from '../types';

// Note: useValidate - Named briefly as hook could export multiple validations
type UseValidateReturns = {
    validateFileIsAllowedTypeAndSize: (
        file: File,
        allowedTypes?: string[],
        maxFileSize?: number,
    ) => EmailAllowedFileValidationType;
};
export const useValidate = (showAlert: AlertContextType['showAlert']): UseValidateReturns => {
    const t = useTranslations('AssetLibrary');
    const validateFileIsAllowedTypeAndSize = (
        file: File,
        allowedTypes?: string[],
        maxFileSize?: number,
    ): EmailAllowedFileValidationType => {
        let message;
        if (maxFileSize && allowedTypes) {
            if (file.size > maxFileSize) {
                message = t('This file is over 2MB and too large to use in an email');
                showAlert('error', message);
                return { isValid: false, message };
            }
            if (!allowedTypes.includes(file.type)) {
                message = t('This file type is unsupported to use in an email');
                showAlert('error', message);
                return { isValid: false, message };
            }
            return { isValid: true, message: null };
        } else {
            return { isValid: true, message: null };
        }
    };
    return {
        validateFileIsAllowedTypeAndSize,
    };
};
