'use client';

import { useSuspenseQuery } from '@apollo/client';
import { GET_CLIENT_OR_NULL_CHECK } from '@deltasierra/frontend/graphql';
import { isNullOrUndefined } from '@deltasierra/type-utilities';

export function useCanAccessClientCheck(clientId?: string | null): boolean {
    const { data } = useSuspenseQuery(
        GET_CLIENT_OR_NULL_CHECK,
        clientId
            ? {
                  variables: {
                      clientId,
                  },
              }
            : { skip: true },
    );

    if (isNullOrUndefined(data)) {
        return false;
    }

    const client = data.client;

    if (isNullOrUndefined(client)) {
        return false;
    }

    return true;
}
