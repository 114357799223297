'use client';

import { useEffect } from 'react';
import { AppGlobalData } from '../types';
import { useHotjar } from './useHotjar';

export function useRegisterHotjarUser(data: AppGlobalData): void {
    const hotjar = useHotjar();

    useEffect(() => {
        if (!hotjar.isReady) {
            return;
        }
        const user = data.me;
        hotjar.identify(user.id.toString(), {
            'First Name': user.firstName,
            'Last Name': user.lastName,
            email: user.username,
        });
    }, [data, hotjar, hotjar.isReady]);
}
