import { graphql } from '../../__graphqlTypes';

export const ASSET_FILE_FRAGMENT = graphql(`
    fragment AssetFileFragment on AssetFile {
        id
        mimeType
        thumbnails {
            small {
                signedUrl
                height
                width
            }
            medium {
                signedUrl
                height
                width
            }
            large {
                signedUrl
                height
                width
            }
        }
        signedUrl
        size
        title
        type
        url
    }
`);
