import { FluidGridItemProps, Skeleton } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { AssetLibraryThumbnail, AssetLibraryThumbnailProps } from './AssetLibraryThumbnail';
import { ModalAsset } from './contexts';

type AssetLibraryBrowserGridItemProps = FluidGridItemProps<
    Pick<AssetLibraryThumbnailProps, 'folderActions'> & {
        allowedTypes?: string[];
        isLoadingAssets: boolean;
        items: ModalAsset[];
        maxFileSize: number;
        onClickThumbnail: (asset: ModalAsset) => void;
        t: Translations<'AssetLibrary'>;
    }
>;

export function AssetLibraryBrowserGridItem({
    data: { allowedTypes, folderActions, isLoadingAssets, items, maxFileSize, onClickThumbnail, t },
    dimensions,
    key,
}: AssetLibraryBrowserGridItemProps): JSX.Element {
    const asset = items.find(item => item.id === key);
    if (!asset) {
        // This should never happen
        return <></>;
    }
    const isFile = asset.__typename === 'AssetFile';
    const isInvalidSize = isFile && asset.size > maxFileSize;
    const isInvalidType = isFile && allowedTypes && allowedTypes.length > 0 && !allowedTypes.includes(asset.mimeType);
    const isDisabled = !!isInvalidSize || !!isInvalidType;
    return isLoadingAssets ? (
        <Skeleton {...dimensions} variant="rectangular" />
    ) : (
        <AssetLibraryThumbnail
            asset={asset}
            dimensions={dimensions}
            disabled={isDisabled}
            folderActions={folderActions}
            t={t}
            onClickThumbnail={() => (isDisabled ? null : onClickThumbnail(asset))}
        />
    );
}
AssetLibraryBrowserGridItem.displayName = 'AssetLibraryBrowserGridItem';
