import { graphql } from '../../__graphqlTypes';

export const GET_UPLOADED_COLLECTION = graphql(`
    query GetUploadedCollectionId($id: ID!) {
        location(id: $id) {
            id
            defaultAssetLibraryCollection {
                id
                title
            }
        }
    }
`);
