import { graphql } from '../../__graphqlTypes';

export const UPLOAD_ASSET_TO_ASSET_LIBRARY = graphql(`
    mutation CreateFile($input: CreateAssetFileInput!) {
        createAssetFile(input: $input) {
            ... on AssetFile {
                id
                mimeType
                signedUrl
                size
                tags
                title
                type
                url
            }
            ... on ValidationError {
                message
            }
            ... on CollectionOrAssetNotFoundError {
                message
            }
            ... on CollectionOrAssetPermissionError {
                message
            }
        }
    }
`);
