import { Box } from '@deltasierra/react/components/core';
import { AssetLibraryBrowserGrid, AssetLibraryBrowserGridProps } from './AssetLibraryBrowserGrid';
import { AssetLibraryNavigator, AssetLibraryNavigatorProps } from './AssetLibraryNavigator';

export type AssetLibraryBrowserProps = AssetLibraryNavigatorProps &
    Omit<AssetLibraryBrowserGridProps, 'collectionTitle'>;

export const AssetLibraryBrowser = ({
    allowedTypes,
    assets,
    collections,
    currentCollection,
    currentFolders,
    folderActions,
    isLoadingAssets,
    isLoadingCollections,
    isOpenCollection,
    maxFileSize,
    onClickBreadcrumb,
    onClickHeader,
    onClickSelectCollection,
    onClickThumbnail,
    t,
    watchElementRef,
}: AssetLibraryBrowserProps): JSX.Element => (
    <Box
        sx={{
            columnGap: '1.5rem',
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '100%',
            minHeight: '100%',
        }}
    >
        <AssetLibraryNavigator
            collections={collections}
            currentCollection={currentCollection}
            isLoadingCollections={isLoadingCollections}
            isOpenCollection={isOpenCollection}
            t={t}
            onClickHeader={onClickHeader}
            onClickSelectCollection={onClickSelectCollection}
        />
        <AssetLibraryBrowserGrid
            allowedTypes={allowedTypes}
            assets={assets}
            collectionTitle={currentCollection?.title}
            currentFolders={currentFolders}
            folderActions={folderActions}
            isLoadingAssets={isLoadingAssets}
            isLoadingCollections={isLoadingCollections}
            maxFileSize={maxFileSize}
            t={t}
            watchElementRef={watchElementRef}
            onClickBreadcrumb={onClickBreadcrumb}
            onClickThumbnail={onClickThumbnail}
        />
    </Box>
);

AssetLibraryBrowser.displayName = 'AssetLibraryBrowser';
