import {
    Box,
    Checkbox,
    FormControlLabel,
    FormControlLabelProps,
    FormGroup,
    Modal,
    Typography,
} from '@deltasierra/react/components/core';
import { AlertTriangle } from '@deltasierra/react/icons';
import { theme } from '@deltasierra/react/theme';
import { Translations } from '@deltasierra/translations/react';

export type AssetLibraryUploadPermissionModalProps = {
    isCheckedConfirmRead: boolean;
    isCheckedNeverAgain?: boolean;
    onChangeConfirmRead: FormControlLabelProps['onChange'];
    onChangeNeverAgain: FormControlLabelProps['onChange'];
    onClickUploadCancel: () => void;
    onClickUploadNext: () => void;
    t: Translations<'AssetLibrary'>;
};

export const AssetLibraryUploadPermissionModal = ({
    isCheckedConfirmRead,
    isCheckedNeverAgain,
    onChangeConfirmRead,
    onChangeNeverAgain,
    onClickUploadCancel,
    onClickUploadNext,
    t,
}: AssetLibraryUploadPermissionModalProps): JSX.Element => (
    <Modal
        buttonCancelText={t('Cancel')}
        buttonNextText={t('Confirm')}
        isDisabledNext={!isCheckedConfirmRead}
        open
        sx={{ maxWidth: '30rem' }}
        title={
            <>
                <AlertTriangle
                    style={{
                        color: theme.palette.warning.main,
                        marginTop: -6,
                    }}
                />
                &nbsp;{t('Do you have permission?')}
            </>
        }
        onCancel={onClickUploadCancel}
        onNext={onClickUploadNext}
    >
        <Box sx={{ color: 'grey.800' }}>
            <Typography variant="body1">
                {`${t('By choosing and image you confirm that you have permission to use the image publicly')}. 
                ${t('Be careful when using sources like {GoogleImages} without seeking permission first', {
                    GoogleImages: 'Google Images',
                })}.`}
            </Typography>
            <FormGroup sx={{ paddingTop: '1rem' }}>
                <FormControlLabel
                    checked={isCheckedConfirmRead}
                    control={<Checkbox />}
                    label={t('I confirm I have read this')}
                    onChange={onChangeConfirmRead}
                />
                {typeof isCheckedNeverAgain !== 'undefined' && (
                    <FormControlLabel
                        checked={isCheckedNeverAgain}
                        control={<Checkbox />}
                        label={t("Please don't show me this again")}
                        onChange={onChangeNeverAgain}
                    />
                )}
            </FormGroup>
        </Box>
    </Modal>
);

AssetLibraryUploadPermissionModal.displayName = 'AssetLibraryUploadPermissionModal';
